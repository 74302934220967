import styled from '@emotion/styled';
import { css } from '@emotion/react';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import Item from '../../components/Item';
import firebase, { useCollectionPopulate } from '../../services/Firestore';
import { primary } from '../../components/colors';
import { EmployeeLink } from '../../components/Link';

const ProjectsSection = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1rem;
  width: 80%;
`;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: ${primary};
`;

const ListProjects = () => {
  const [value, loading] = useCollectionPopulate(firebase.firestore()
    .collection('projects').limit(15), []);

  return (
    <>
      {
        loading ? <ClipLoader loading={loading} size={150} css={override} />
          : (
            <ProjectsSection>
              {
              value.map((project) => (
                <EmployeeLink to={`/project/${project.id}`}>
                  <Item
                    imageItem={project.imageURL}
                    title={project.name}
                    descriptin={project.description}
                  />
                </EmployeeLink>
              ))
            }

            </ProjectsSection>
          )
      }

    </>
  );
};

export default ListProjects;
