/* eslint-disable */
import styled from '@emotion/styled';
import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useCollection } from 'react-firebase-hooks/firestore';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import firebase from '../../services/Firestore';
import createProfile from '../../services/api/profile';
import Input from '../../components/Input';
import defaultImageProfile from './defaultImageProfile.jpg';
import Button from '../../components/Button';
import { defaultFont } from '../../components/font';

const AdminSection = styled.form`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 80%;
`;

const TextNotification = styled.div`
  font-family:  ${defaultFont};
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 10px;
  color: #ff0000;
`;

const Container = styled.div`
`;

const AdminPageEmployee = () => {
  const history = useHistory();

  const [flag, setFlag] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedManager, setSelectedManager] = useState('');

  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const [imgURL, setImgURL] = useState(defaultImageProfile);
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();
  const [positionsValue] = useCollection(
    firebase.firestore().collection('positions'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  const [departmentsValue] = useCollection(
    firebase.firestore().collection('departments'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  const [employees] = useCollection(
    firebase.firestore().collection('employees'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const optionsPosition = useMemo(() => positionsValue?.docs
    .map((doc) => ({ value: doc.id, label: doc.data().name })),
    [positionsValue]);

  const optionsDepartment = useMemo(() => departmentsValue?.docs
    .map((doc) => ({ value: doc.id, label: doc.data().name_ru })),
    [departmentsValue]);

  //Eslint ругается на эту строчку, но его исправление не работает
  const optionsManager = employees?.docs
    .map((doc) => (
      { value: doc, label: (doc.data().name + ' ' + doc.data().surname) }));

  const onSubmit = async (data) => {
    if (selectedDepartment && selectedManager && selectedPosition) {
        const manager = selectedManager?.value.id;
        const result = await createProfile(data,
        selectedFile, selectedPosition, selectedDepartment, manager);

      if (result === true) {
        history.push('/list-employees');
      } else {
        NotificationManager.warning(result, 'Error');
      }
    }
    else {
      setFlag(true)
    }

  };
  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgURL(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  return (
    <AdminSection onSubmit={handleSubmit(onSubmit)}>
      <div><h1>Добавление пользователя</h1></div>
      <div>
        <img src={imgURL} alt="Profile" />
      </div>
      <Input
        type="file"
        onChange={(e) => handleChange(e)}
        accept="image/png, .jpeg, .jpg, image/gif"
      />
      <Container>
        <div></div>
      </Container>
      {errors.email?.type === 'required'
        && <TextNotification>Email не заполнен</TextNotification>}
      <Input
        large
        placeholder="Email"
        type="email"
        {...register('email', { required: true })}
      />
      {errors.password?.type === 'required'
        && <TextNotification>Пароль не заполнен</TextNotification>}
      <Input
        large
        placeholder="Пароль"
        type="password"
        {...register('password', { required: true })}
      />
      {errors.name?.type === 'required'
        && <TextNotification>Имя не заполнено</TextNotification>}
      <Input
        large
        placeholder="Имя"
        {...register('name', { required: true })}
      />
      {errors.surname?.type === 'required'
        && <TextNotification>Фамилия не заполнена</TextNotification>}
      <Input
        large
        placeholder="Фамилия"
        {...register('surname', { required: true })}
      />
      {errors.phoneNumber?.type === 'required'
        && <TextNotification>Номер телефона не заполнен</TextNotification>}
      {errors.phoneNumber?.type === 'minLength'
        && <TextNotification>Минимальная длина телефона 6</TextNotification>}
      <Input
        large
        placeholder="Номер телефона"
        {...register('phoneNumber',
          { required: true, minLength: 6 })}
      />
      <Input large placeholder="instagram" {...register('instagram')} />
      <Input large placeholder="telegram" {...register('telegram')} />
      <Input large placeholder="vk" {...register('vk')} />
      {flag && <TextNotification>Поля не заполнены</TextNotification>}
      <Select
        placeholder="Позиция"
        defaultValue={selectedPosition}
        onChange={setSelectedPosition}
        options={optionsPosition}
      />
      <br />
      <Select
        placeholder="Отдел"
        defaultValue={selectedDepartment}
        onChange={setSelectedDepartment}
        options={optionsDepartment}
      />
      <br />
      <Select
        placeholder="Менеджер"
        defaultValue={selectedManager}
        onChange={setSelectedManager}
        options={optionsManager}
      />
      <Input large placeholder="Прочая информация" {...register('about')} />
      <br />
      <Button>Добавить</Button>
      <NotificationContainer />
    </AdminSection>

  );
};

export default AdminPageEmployee;
