/* eslint-disable */
import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import ProjectImage from '../ListProjects/img.svg';
import firebase from '../../services/Firestore';
import { defaultFont } from '../../components/font.js'
import { inactive, primary } from '../../components/colors'
import EmployeeItem from '../../components/Item';
import avatar2 from '../ListEmployees/SecondImg.png'
import { useDocumentPopulate } from '../../services/Firestore';

const ProjectWrapper= styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${defaultFont};
    
`
const Tabs = styled.div`
    display: flex;
`

const Tab = styled.div`
    & input{
      display: none;
    }
    & label{  color: ${inactive};
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 9px;
      padding-right: 45px;
      padding-left: 45px;
      transition: .5s ease-out;
      
      ${(props)=>props.checked && `
          transition: .5s;
          color: ${primary};
          border-bottom: 2px solid ${primary};
      `}
    }
`

const Info = styled.div`
    padding-top: 86px;
    text-align: center;
    & h1{
      font-weight: 600;
      font-size: 32px;
      color: #000000;
      opacity: 0.3;
      line-height: 14px;
    }
    
`
const Title = styled.h2`
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    line-height: 14px;
`

const ProjectImg = styled.img`
    max-width: 1024px;
    max-height: 251px;
    margin: 28px 0;
`
const Description = styled.div`
    font-size: 18px;
    line-height: 18px;
    min-width: 465px;
`

const Employees = styled.div`
    display: grid;
    justify-content: space-between;
    margin-top: 99px;
    margin-left: 9%;
    margin-right: 9%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 145px;
`

const Manager = styled.div`
    display: grid;
    align-items: center;
    align-content: flex-start;
    justify-items: center;
    grid-row-gap: 57px;
`

const Programmers = styled.div`
    display: grid;
    align-items: center;
    align-content: flex-start;
    justify-items: center;
    grid-row-gap: 57px;

`
const db=firebase.firestore()

let ProgrammersList = (props) =>{
  let employees = props.employees.map((employee)=> useDocumentPopulate(employee.path, ['position', 'department']))
  employees = employees.map((employee)=> {if(employee!=null) return <EmployeeItem 
    imageItem={employee.imageURL}
    title = {employee.name}
    subtitle = {employee.position.name}
    descriptin = {employee.about} 
  />})
  return(<Programmers>
    <Title>Разработчики</Title>
    {employees}
  </Programmers>)
}

const Project = (props) => {
  let projectId = props.match.params.id
  const [tabsFlag, setTabsFlag] = useState(true)
  const project = useDocumentPopulate(
    `projects/${projectId}`, ['projectManager'],
  )
  return (
    <>
       {project ? <ProjectWrapper>
          <Tabs>
              <Tab onChange={()=>setTabsFlag(true)} checked={tabsFlag} tabsFlag><label htmlFor="info">Информация<input name='tab' id='info' type="radio" /></label></Tab>
              <Tab onChange={()=>setTabsFlag(false)} checked={!tabsFlag}><label htmlFor="employees">Сотрудники<input name='tab' id="employees" type="radio" /></label></Tab>
          </Tabs>
          {tabsFlag && <Info id="info">
              <h1>{project.fullName}</h1>
              <ProjectImg src={project.imageURL} alt="" />
              <Description>{project.description}</Description>
          </Info>}
          {!tabsFlag && !!project && <Employees>
             <Manager>
              <Title>Менеджер</Title>
              {!!project.projectManager &&
                <EmployeeItem
                imageItem={project.projectManager.imageURL}
                title = {project.projectManager.name}
                subtitle = {project.projectManager.position.name}
                descriptin = {project.projectManager.about}
                />
              }
            </Manager>
              {!!project.employees && <ProgrammersList employees={project.employees}/>}
          </Employees>}
      </ProjectWrapper> : <div></div>}
    </>
  );
};

export default Project;
