import styled from '@emotion/styled';
import { NavLink, Link } from 'react-router-dom';
import { primary } from './colors';
import { defaultFont } from './font';

const SimpleLink = styled(NavLink)`
    font-family:  ${defaultFont}, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: ${primary};
    text-decoration: none;
    &:visited{
        text-decoration: none;
    }
`;

export const HeaderLink = styled(Link)`

  background: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  font-family: ${defaultFont};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  padding: 10px 8px;
  margin: 5px;
  text-decoration: none;
  color: #000000;
  &:hover{
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  } 
`;

export const EmployeeLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

export default SimpleLink;
