/* eslint-disable */
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCollection } from 'react-firebase-hooks/firestore';
import CustomSelect from '../../components/Select';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import firebase from '../../services/Firestore';
import createProject from '../../services/api/project';
import Input from '../../components/Input';
import defaultImageProfile from './defaultImageProfile.jpg';
import Button from '../../components/Button';
import { defaultFont } from '../../components/font';

const AdminSection = styled.form`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 80%;
`;

const TextNotification = styled.div`
  font-family:  ${defaultFont};
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 10px;
  color: #ff0000;
`;

const AdminPageProject = () => {
  const history = useHistory();
  const [selectedManager, setSelectedManager] = useState('empty');
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [selectedFile, setSelectedFile] = useState('');

  const [imgURL, setImgURL] = useState(defaultImageProfile);
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const [employees] = useCollection(
    firebase.firestore().collection('employees'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const options = employees?.docs
    .map((doc) => ({ value: doc, label: doc.data().name +' '+ doc.data().surname }));

  const onSubmit = async (data) => {
    if (selectedManager !== 'empty' && selectedManager !== 'emptyWarning') {
      const manager = selectedManager?.value.id;
      const listEmployees = selectedEmployees?.map((item) => item?.value.id);
      const result = await createProject(data, selectedFile,
        manager, listEmployees);
      if (result === true) {
        history.push('/list-projects');
      } else {
        NotificationManager.warning(result, 'Error');
      }
    } else {
      setSelectedManager('emptyWarning');
    }
  };

  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgURL(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <AdminSection onSubmit={handleSubmit(onSubmit)}>
      <div><h1>Добавление проекта</h1></div>
      <div>
        <img src={imgURL} alt="Profile" />
      </div>

      <Input
        type="file"
        accept="image/png, .jpeg, .jpg, image/gif"
        onChange={(e) => handleChange(e)}
      />
      {errors.name?.type === 'required'
      && <TextNotification>Название не заполнено</TextNotification>}
      <Input
        large
        placeholder="Название"
        {...register('name', { required: true })}
      />
      {errors.fullName?.type === 'required'
      && <TextNotification>Полное название не заполнено</TextNotification>}
      <Input
        large
        placeholder="Полное название"
        {...register('fullName', { required: true })}
      />
      {errors.description?.type === 'required'
      && <TextNotification>Описание не заполнено</TextNotification>}
      <Input
        large
        placeholder="Описание"
        {...register('description', { required: true })}
      />
      {selectedManager === 'emptyWarning'
      && <TextNotification>Менеджер не выбран</TextNotification>}
      <CustomSelect
        placeholder="Менеджер"
        defaultValue={selectedManager}
        onChange={setSelectedManager}
        options={options}
      />
      <br />
      <CustomSelect
        placeholder="Сотрудники"
        defaultValue={selectedEmployees}
        onChange={setSelectedEmployees}
        options={options}
        isMulti
      />
      <br />
      <Button>Добавить</Button>
      <NotificationContainer />
    </AdminSection>

  );
};

export default AdminPageProject;
