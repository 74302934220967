import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { React } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes, { string } from 'prop-types';
import { useDocumentPopulate } from '../../services/Firestore';
import { primary } from '../../components/colors';

const Info = styled.div`
display: grid;
grid-template-columns: 2fr 2fr;
font-size:30px;
width: 100%;
height:100%;
padding:10px;
margin: auto; 
text-align: center;
`;

const EmpInfo = styled.div`
font-size:30px;
padding:10px;
margin-left: 10%; 
text-align: left;
`;

const ManagerInfo = styled.div`
display: grid;
grid-template-columns: 2fr 2fr;
padding: 5%;
margin: auto;
width: 60%;
font-size: 15px;
`;

const Name = styled.p`
font-weight: bold;
font-family: Verdana;
text-align: center;
`;

const Post = styled.p`
font-weight: 50;
font-family: Verdana;
`;

const LifeWords = styled.p`
font-weight: 400;
font-family: Verdana;
`;

const ObjectEmp = styled.p`
`;

const ProfilePhoto = styled.img`
width: 40%;
height: auto;
text-align: center;
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: ${primary};
`;

const ManagerPhoto = styled.img`
width: 70%;
height: auto;
text-align: center;
`;

const EmployeeInfo = ({ match }) => {
  const employeeId = match.params.id;

  const employee = useDocumentPopulate(
    `employees/${employeeId}`,
    ['position', 'department', 'networks', 'manager'],
  );

  return (
    <>{employee ? (
      <Info>
        <div>
          <Name>{`${employee.name} ${employee.surname}`}</Name>
          <ProfilePhoto src={employee.imageURL} alt="UserPhoto" />
          <Post>{employee.position.name}</Post>
          <LifeWords>{employee.about}</LifeWords>
        </div>
        <div>
          <EmpInfo>
            <ObjectEmp>Телефон: {employee.phoneNumber}</ObjectEmp>
            <ObjectEmp>Отдел: {employee.department.name_ru}</ObjectEmp>
          </EmpInfo>
          <h2>Менеджер</h2>
          { employee.manager
            && (
            <ManagerInfo>
              <ManagerPhoto src={employee.manager.imageURL} alt="UserPhoto" />
              <div>
                <Name>{`${employee.manager.name} 
                ${employee.manager.surname}`}
                </Name>
                <LifeWords>{employee.manager.about}</LifeWords>
              </div>
            </ManagerInfo>
            )}
        </div>
      </Info>
    ) : <ClipLoader employee={employee} size={150} css={override} />}

    </>

  );
};

export default EmployeeInfo;

EmployeeInfo.defaultProps = {
  match: string,
};

EmployeeInfo.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};
