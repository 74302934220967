import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './img/logo.svg';
import firebase from '../services/Firestore';
import { getCurrentUser } from '../services/api/user';
import DropDownMenu from './DropDownMenu';
import { HeaderLink } from './Link';

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px ;
`;

const NavLinkContainer = styled.div`
  display: flex;
`;

const Header = () => {
  const currentUser = getCurrentUser();
  return (
    <HeaderContainer>
      <Link to="/list-employees"><Logo /></Link>
      <NavLinkContainer>
        <HeaderLink to="/list-employees">Сотрудники</HeaderLink>
        <HeaderLink to="/list-projects">Проекты</HeaderLink>
        <HeaderLink to="/settings">Настройки</HeaderLink>
        {currentUser.isAdmin && (
        <DropDownMenu
          title="Добавление"
          dropDownList={[
            <HeaderLink to="/admin-employee">Пользователей</HeaderLink>,
            <HeaderLink to="/admin-project">Проектов</HeaderLink>]}
        />
        )}

        <HeaderLink onClick={() => firebase.auth().signOut()}>Выйти</HeaderLink>
      </NavLinkContainer>

    </HeaderContainer>
  );
};

export default Header;
