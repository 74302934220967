import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useState, useEffect, React } from 'react';
import { useForm } from 'react-hook-form';
import { useCollection } from 'react-firebase-hooks/firestore';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from '../../components/Select';
import { EmployeeLink } from '../../components/Link';
import Item from '../../components/Item';
import Button from '../../components/Button';
import firebase, { useCollectionPopulate } from '../../services/Firestore';
import { primary } from '../../components/colors';

const EmployeeSection = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1rem;
  width: 80%;
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: ${primary};
`;

const FlexBox = styled.div`
display: flex;
margin: auto;
flex-direction: column;
align-items: center;
margin-top: 5%;
width: 60%;
`;

const ListEmployees = () => {
  const {
    handleSubmit,
  } = useForm();
  const [value, loading] = useCollectionPopulate(firebase.firestore()
    .collection('employees').limit(15), ['position', 'department']);
  const [view, setView] = useState([]);
  const [positionsValue] = useCollection(
    firebase.firestore().collection('positions'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);
  // const [selectedProject, setSelectedProject] = useState(undefined);
  const [selectedPost, setSelectedPost] = useState(undefined);
  const [departmentsValue] = useCollection(
    firebase.firestore().collection('departments'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  // const [projectValue] = useCollection(
  //   firebase.firestore().collection('projects'),
  //   {
  //     snapshotListenOptions: { includeMetadataChanges: true },
  //   },
  // );
  const departments = departmentsValue?.docs.map((doc) => (
    { value: doc.data().name_ru, label: doc.data().name_ru }));
  const posts = positionsValue?.docs.map((doc) => (
    { value: doc.data().name, label: doc.data().name }));
  // const projects = projectValue?.docs.map((doc) => (
  //   { value: doc, label: doc.data().name }));

  const onSubmit = () => {
    let result = value.slice();
    if (!!selectedDepartment && selectedDepartment != null) {
      result = result.filter(
        (user) => user.department.name_ru === selectedDepartment.value,
      );
    }
    if (!!selectedPost && selectedPost != null) {
      result = result.slice().filter(
        (user) => user.position.name === selectedPost.value,
      );
    }
    // if (selectedProject !== undefined) {
    //   result = result.slice().filter(
    //     (user) => user.projects.indexOf(selectedProject.value),
    //   );
    // }
    setView(result);
  };
  useEffect(() => {
    const res = value.slice();
    if (!loading) setView(res);
  }, [value, loading, setView]);

  return (
    <>
      <FlexBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            defaultValue={selectedDepartment}
            onChange={setSelectedDepartment}
            options={departments}
            placeholder="Department"
            isClearable
          />
          <Select
            defaultValue={selectedPost}
            onChange={setSelectedPost}
            options={posts}
            placeholder="Position"
            isClearable
          />
          {/* <Select
            defaultValue={selectedProject}
            onChange={setSelectedProject}
            options={projects}
            placeholder="Project"
          /> */}
          <Button type="submit">Поиск</Button>
        </form>
      </FlexBox>
      {
        loading ? <ClipLoader loading={loading} size={150} css={override} />
          : (
            <EmployeeSection>
              {
                view.map((employee) => (
                  <>
                    <EmployeeLink to={`/info/${employee.id}`}>
                      <Item
                        imageItem={employee.imageURL}
                        title={`${employee.name} ${employee.surname}`}
                        subtitle={employee.position.name}
                        descriptin={employee.about}
                      />
                    </EmployeeLink>
                  </>
                ))
              }
            </EmployeeSection>
          )
      }
    </>
  );
};

export default ListEmployees;
