import { useState, useEffect } from 'react';
import firebase from '../Firestore';

// eslint-disable-next-line import/prefer-default-export
export const getCurrentUser = () => {
  const [data, setData] = useState('');
  const fetch = async () => {
    await firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const fields = ['position', 'department', 'networks'];
        const response = await firebase.firestore()
          .doc(`employees/${user.uid}`);
        const doc = await response.get();
        const promises = await Promise
          .all(fields.map(async (field) => doc.data()[field].get()));

        const dateWithPromise = {
          ...doc.data(),
          id: doc.id,
        };

        fields.forEach((field, index) => {
          dateWithPromise[field] = promises[index].data();
        });
        setData(dateWithPromise);
      }
    });
  };
  useEffect(() => {
    if (!data) {
      fetch();
    }
  }, []);
  return data;
};
