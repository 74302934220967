import firebase from '../Firestore';

const config = {
  apiKey: 'AIzaSyCbXCm0tseDskH60xiv4Tu8rmddRd7g5TQ',
  authDomain: 'prac-summer-2021.firebaseapp.com',
  projectId: 'prac-summer-2021',
  storageBucket: 'prac-summer-2021.appspot.com',
  messagingSenderId: '886771092446',
  appId: '1:886771092446:web:add0cd105c0c625fe96b4f',
  measurementId: 'G-GSFVRG9SEH',
};
const secondaryApp = firebase.initializeApp(config, 'Secondary');

const db = firebase.firestore();
const createProfile = async (data, file, position, department, manager) => {
  try {
    const res = await secondaryApp.auth()
      .createUserWithEmailAndPassword(data.email, data.password);
    const user = (res.user.uid);
    secondaryApp.auth().signOut();

    await firebase.storage()
      .ref(`users/${user.uid}/profile.jpg`).put(file);

    const imageURL = await firebase.storage()
      .ref(`users/${user.uid}/profile.jpg`).getDownloadURL();

    const refNetworks = await db.collection('networks').doc(user.uid);

    await db.collection('employees').doc(user.uid).set({
      name: data.name,
      surname: data.surname,
      phoneNumber: data.phoneNumber,
      position: db.doc(`positions/${position.value}`),
      department: db.doc(`departments/${department.value}`),
      manager: db.doc(`employees/${manager}`),
      about: data.about,
      isAdmin: false,
      networks: refNetworks,
      imageURL,
    });

    await refNetworks.set({
      instagram: data.instagram,
      telegram: data.telegram,
      vk: data.vk,
    });

    return true;
  } catch (e) {
    return e.message;
  }
};

export default createProfile;
