import firebase from '../Firestore';

const db = firebase.firestore();
const createProject = async (data, file, manager, listEmployees) => {
  try {
    const refProject = db.collection('projects').doc();

    await firebase.storage()
      .ref(`projects/${refProject.id}/project.jpg`).put(file);

    const imageURL = await firebase.storage()
      .ref(`projects/${refProject.id}/project.jpg`).getDownloadURL();

    listEmployees.map(async (em) => {
      const ref = db.doc(`employees/${em}`);
      const dataEm = await (await ref.get()).data();
      if (dataEm.projects) {
        ref.update({ projects: [...dataEm.projects, refProject] });
      } else {
        ref.update({ projects: [refProject] });
      }
    });
    await refProject.set({
      name: data.name,
      fullName: data.fullName,
      description: data.description,
      projectManager: db.doc(`employees/${manager}`),
      employees: listEmployees.map((em) => db.doc(`employees/${em}`)),
      imageURL,
    });
    return true;
  } catch (e) {
    return e.message;
  }
};

export default createProject;
