export const accentRed = '#FF5B25';
export const hoverRed = '#F34911';
export const focusRed = '#E44B19';
export const text = '#0C0C0C';
export const background = '#FFFFFF';
export const inputBackground = '#FEFDFD';
export const borderColor = '#E0E0E0';
export const placeholder = '#8B8B8B';
export const primary = '#3B59F5';
export const green = '#3B59F5';
export const inactive = '#7A7A7A';
