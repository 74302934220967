/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import Select from 'react-select';
import { placeholder, borderColor } from './colors';
import { defaultFont } from './font';
import arrow from './img/select_arrow.svg';

const CustomSelect = styled(Select)`
    min-height: 40px;
    min-width: 244px;
  select{
    border: 1px solid ${borderColor};
    border-radius: 4px;
    padding: 12px 20px 13px;
    appearance: none;
    font-family: ${defaultFont} sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    outline: none;
    background: url(${arrow}) 96% / 15% no-repeat;
  }

  & option [disabled]{
    color: ${placeholder};
  }
`;

export default CustomSelect;
