import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { defaultFont } from './font';

const TextContainer = styled.div`
  padding: 10px;
  text-align: left;
`;

const Subtitle = styled.div`
  font-family: ${defaultFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
`;

const Descriptin = styled.div`
  margin-top: 10px;
  font-family: ${defaultFont};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
`;

const Item = styled.div`
  padding: 20px;
  display: flex;
  text-align: center;
`;
const ItemImage = styled.img`
  max-width: 200px;
  height: auto;
  border-radius: 8px;
`;

const EmployeeItem = (props) => {
  const {
    imageItem,
    title,
    subtitle,
    descriptin,
  } = props;
  return (
    <Item>
      <div>
        <ItemImage src={imageItem} />
      </div>
      <TextContainer>
        <h2>{title}</h2>
        <Subtitle>
          {subtitle}
        </Subtitle>
        <Descriptin>
          {descriptin}
        </Descriptin>
      </TextContainer>
    </Item>
  );
};
export default EmployeeItem;

EmployeeItem.propTypes = {
  imageItem: PropTypes.bool.isRequired,
  title: PropTypes.bool.isRequired,
  subtitle: PropTypes.bool.isRequired,
  descriptin: PropTypes.bool.isRequired,
};
