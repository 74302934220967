/* eslint-disable */
import firebase from 'firebase';
import 'firebase/firestore';
import { useState, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

const config = {
  apiKey: 'AIzaSyCbXCm0tseDskH60xiv4Tu8rmddRd7g5TQ',
  authDomain: 'prac-summer-2021.firebaseapp.com',
  projectId: 'prac-summer-2021',
  storageBucket: 'prac-summer-2021.appspot.com',
  messagingSenderId: '886771092446',
  appId: '1:886771092446:web:add0cd105c0c625fe96b4f',
  measurementId: 'G-GSFVRG9SEH',
};
firebase.initializeApp(config);
const db = firebase.firestore();
export const getDataByFields = (fields) => async (data) => {
  const promises = await Promise
    .all(fields.map(async (field) => data.data()[field].get()));
  const dateWithPromise = {
    ...data.data(),
    id: data.id,
  };
  fields.forEach((field, index) => {
    dateWithPromise[field] = promises[index].data();
  });
  return dateWithPromise;
};

const populate = async (values,
  fields, setData = () => { }, setLoading = () => { }) => {
  setLoading(true);
  const valuesWithPopulate = await Promise.all(values
    .map(getDataByFields(fields)));
  setData(valuesWithPopulate);
  setLoading(false);
};
export const useCollectionPopulate = (query, fields) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [value, innerLoading, error] = useCollection(
    query,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  useEffect(() => {
    if (value?.docs) {
      populate(value.docs, fields, setData, setLoading);
    }
  }, [innerLoading]);
  return [data, loading, error];
};

export const useDocumentPopulate = (query, fields) => {
  if (query) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = async () => {
      const response = await firebase.firestore().doc(query);
      const doc = await response.get();
      if(!!doc.data()){
      const promises = await Promise
        .all(fields.map(async (field) => doc.data()[field].get()));

      const dateWithPromise = {
        ...doc.data(),
        id: doc.id,
      };

      fields.forEach((field, index) => {
        dateWithPromise[field] = promises[index].data();
      });
      
      setData(dateWithPromise);
      }
      setLoading(true);
    };
    useEffect(() => {
      if (!loading) {
        fetch();
      }
    }, [loading]);
    return data;
  }
  else{
    return []
  }

};

export const createProfile = async (data, file, position, department) => {
  try {
    const { user } = await firebase.auth()
      .createUserWithEmailAndPassword(data.email, data.password);
    try {
      await firebase.storage()
        .ref(`users/${user.uid}/profile.jpg`).put(file);
    } catch (e) {
      console.log(e);
    }
    const imageURL = await firebase.storage()
      .ref(`users/${user.uid}/profile.jpg`).getDownloadURL();

    const refNetworks = await db.collection('networks').doc(user.uid);

    await db.collection('employees').doc(user.uid).set({
      name: data.name,
      surname: data.surname,
      phoneNumber: data.phoneNumber,
      position: db.doc(`positions/${position.value}`),
      department: db.doc(`departments/${department.value}`),
      about: data.about,
      isAdmin: false,
      networks: refNetworks,
      imageURL,
    });

    await refNetworks.set({
      instagram: data.instagram,
      telegram: data.telegram,
      vk: data.vk,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createProject = async (data, file, manager, listEmployees) => {
  try {
    const refProject = db.collection('projects').doc();
    await firebase.storage()
      .ref(`projects/${refProject.id}/project.jpg`).put(file);

    const imageURL = await firebase.storage()
      .ref(`projects/${refProject.id}/project.jpg`).getDownloadURL();
    const employees = listEmployees.map(async (em) => {
      const ref = db.doc(`employees/${em}`);
      const dataEm = await (await ref.get()).data();
      if (dataEm.projects) {
        ref.update({ projects: [...dataEm.projects, refProject] });
      } else {
        ref.update({ projects: [refProject] });
      }
      return ref;
    });
    await refProject.set({
      name: data.name,
      fullName: data.fullName,
      description: data.description,
      projectManager: db.doc(`employees/${manager}`),
      employees: listEmployees.map((em) => db.doc(`employees/${em}`)),
      imageURL,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getImgUrlbd = async (id) => {
  try {
    let imageURL = await firebase.storage().ref(`users/${id}/profile.jpg`).getDownloadURL();
      return imageURL.toString();
  } catch(err) {
    alert(err);
  }
}

export const setImgbd = (id, img) => {
  try {
     firebase.storage().ref(`users/${id}/profile.jpg`).put(img);
  } catch (e) {
    console.log(e);
  }
}
export default firebase;