/* eslint-disable */
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import Logo from '../../components/img/logo.svg';
import Input from '../../components/Input';
import Button1 from '../../components/Button';
import firebase, { authUser, useCollectionPopulate, useDocumentPopulate } from '../../services/Firestore';
import 'react-notifications/lib/notifications.css';

const Images = styled.img`
width: 50%;
margin: 3%;
text-align: center;
`;

const FlexBox = styled.div`
display: flex;
margin: auto;
flex-direction: column;
align-items: center;
margin-top: 15%;
width: 60%;
`;

const GridItem = styled.div`
margin: 5%;
`;

export default function Login() {
  const history = useHistory();
  const {
    register, handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(
        data.login,
        data.password,
      );
      history.push('/list-employees');
    } catch (e) {
      NotificationManager.warning(e.message, 'Ошибка', 3000);
    }
  };
  return (
    <FlexBox>
      <Images src={Logo} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridItem>
          <Input placeholder="Login" {...register('login')} />
        </GridItem>
        <GridItem>
          <Input
            placeholder="Password"
            type="password"
            {...register('password')}
          />
        </GridItem>
        <Button1 type="submit">Войти</Button1>
        <NotificationContainer />
      </form>
    </FlexBox>
  );
}
