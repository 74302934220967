import styled from '@emotion/styled';
import {
  inputBackground, borderColor, placeholder,
} from './colors';
import { defaultFont } from './font';

const Input = styled.input`

  padding: 12px 20px 13px;
  width: 100%;
  font-family: ${defaultFont}, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid ${borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  background-color:${inputBackground};
  outline: none;

  &::placeholder{
    color: ${placeholder};
  }

  ${(props) => props.large && `
      height: 52px;
      margin: 10px 0; 
      font-size: 20px;
  `};
`;

export default Input;
