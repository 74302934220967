/*eslint-disable*/
import styled from '@emotion/styled';
import react from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import Button from './Button';
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { HeaderLink } from './Link';

const Menu = styled.div`
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  &:active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  } */

`;

const DropDownMenu = (props) => {
  const {title,dropDownList} = props
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  return (
    <>
      <HeaderLink onClick={onClick}>{title}</HeaderLink>

      {isActive ?
        <Menu ref={dropdownRef}>
          {dropDownList.map((prop)=>(
            prop
          ))}
        </Menu>
        : null
      }
    </>
  )
};

export default DropDownMenu;
