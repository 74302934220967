import {
  Switch, Route, useHistory,
} from 'react-router-dom';
import { useEffect } from 'react';
import ListEmployees from './containers/ListEmployees';
import EmployeePage from './containers/EmployeePage';
import AdminPageEmployee from './containers/AdminPageEmployee';
import LoginPage from './containers/LoginPage';
import ListProjects from './containers/ListProjects';
import SettingsPage from './containers/SettingsPage';
import Header from './components/Header';
import firebase from './services/Firestore';
import { getCurrentUser } from './services/api/user';
import AdminPageProject from './containers/AdminPageProject';
import Project from './containers/Project';

const Routes = () => {
  const history = useHistory();
  const currentUser = getCurrentUser();
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        history.push('/login');
      }
    });
  });
  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/">

        <Header />
        <Switch>
          {currentUser?.isAdmin && (
            <Route path="/admin-employee">
              <AdminPageEmployee />
            </Route>
          )}

          {currentUser?.isAdmin && (
          <Route path="/admin-project">
            <AdminPageProject />
          </Route>
          )}
          <Route path="/list-employees">
            <ListEmployees />
          </Route>
          <Route path="/list-projects">
            <ListProjects />

          </Route>
          <Route path="/info/:id" exact component={EmployeePage} />

          <Route path="/project/:id" component={Project} />
          <Route path="/settings">
            <SettingsPage />

          </Route>

        </Switch>
      </Route>

    </Switch>
  );
};

export default Routes;
