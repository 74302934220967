import firebase from '../Firestore';

export const changePassword = (newPassword) => {
  const user = firebase.auth().currentUser;
  try {
    user.updatePassword(newPassword);
    return 'Пароль изменен';
  } catch (e) {
    return e;
  }
};
export const changeData = async (data, user) => {
  try {
    const db = firebase.firestore();
    const refProject = db.collection('employees').doc(user);

    await db.collection('networks').doc(user).set(
      {
        vk: data.vk,
        instagram: data.instagram,
        telegram: data.telegram,
        linkedIn: data.linkedIn,
        facebook: data.facebook,
      },
    );
    await refProject.update({
      phoneNumber: data.phoneNumber,
      about: data.about,
      otherEmail: data.otherEmail,
    });
  } catch (e) {
    console.log(e);
  }
};
