/* eslint-disable */
import styled from '@emotion/styled';
import { useState, React, useEffect } from 'react';
import { getCurrentUser } from '../../services/api/user';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { changePassword, changeData } from '../../services/api/settings';
import { inactive } from '../../components/colors';
import { defaultFont } from '../../components/font';
import { getImgUrlbd, setImgbd } from '../../services/Firestore';
const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 1028px;
  height: 778px;
  margin: 45px auto 0;
`;
const ChildContainer = styled.div`
  width: 50%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:last-child{
    margin-left: 179px;
    margin-top: 30px;
  }
`;
const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 42px;
`;
const PositionEmployee = styled.div`
  font-size: 10px;
  color: #8B8B8B;
  margin-top: 10px;
  margin-bottom: 5px;
`;
const TitleForm = styled.div`
  font-size: 22px;
  color: black;
`;
const ButtonLarge = styled(Button)`
  width: 424px;
  height: 42px;
  margin: 10px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  background: #3B59F5;
`;
const FormPassword = styled.div`
  width: 100%;
`;
const FormInformation = styled.div`
  width: 100%;
`;
const TextNotification = styled.div`
  font-family:  ${defaultFont};
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 10px;
  color: #FF0000;
`;
const Container = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background: ${inactive};
    color: rgba(0,0,0,0);
    transition: .19s;
    font-family: ${defaultFont}, sans-serif;
    font-size: 25px;
    font-weight: 900;
    &:hover{
      transition: .19s;
      box-shadow: 0px 0px 100px 100px rgba(0, 0, 0, 0.7) inset;
      color: #ffffffca;
    }
    ${(props) => props.bgImg &&`
      background: url(${props.bgImg});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      `}
`
const FileInput = styled(Input)`
    display: none;
`
const SettingsPage = () => {
  const currentUser = getCurrentUser();
  const [loading, setLoadin] = useState(false);
  const [otherEmail, setOtherEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [about, setAbout] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [telegram, setTelegram] = useState('');
  const [vk, setVK] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [imgFile, setImgFile] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [passwordFlag, setPasswordFlag] = useState();
  const onSubmitPassword = () => {
    setPasswordFlag(false);
    if (newPassword === newPasswordRepeat) {
      changePassword(newPassword);
    } else {
      setPasswordFlag(true);
    }
  };
  const onSubmit = async () => {
    await changeData({
      otherEmail,
      phoneNumber,
      about,
      linkedIn,
      instagram,
      telegram,
      vk,
      facebook,
    }, currentUser.id);
  };
  const _handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if(file){
      reader.onloadend = () => {
        setImgFile(file);
        setImgUrl(reader.result);
        setImgbd(currentUser.id, file)
      }
      console.log(imgUrl);
      reader.readAsDataURL(file)
    }
  }
  const _handleSubmit = (e) => {
    e.preventDefault();
  }
  useEffect(
    () => {
      if (!loading && currentUser) {
        setOtherEmail(currentUser.otherEmail || '');
        setPhoneNumber(currentUser.phoneNumber || '');
        setAbout(currentUser.about || '');
        setLinkedIn(currentUser.networks.linkedIn || '');
        setInstagram(currentUser.networks.instagram || '');
        setFacebook(currentUser.networks.facebook || '');
        setTelegram(currentUser.networks.telegram || '');
        setVK(currentUser.networks.vk || '');
        setImgUrl(currentUser.imageURL);
        setLoadin(true);
      }
    }, [loading, currentUser],
  );
  return (
    <>
      {currentUser && (
        <MainContainer>
          <ChildContainer>
            <Title>{currentUser.name} {currentUser.surname}</Title>
            <Container htmlFor="loadImg" bgImg={imgUrl} >Сменить фото</Container>
            <form onSubmit={(e)=> _handleSubmit(e)}>
              <FileInput id="loadImg" className="fileInput"
                         type="file"
                         accept="image/png, .jpeg, .jpg, image/gif"
                         onChange={(e)=> _handleImageChange(e)} />
            </form>
            <PositionEmployee>{currentUser.position.name}</PositionEmployee>
            <FormPassword>
              <TitleForm>Сменить пароль</TitleForm>
              <Input large
                     large
                     name="password"
                     placeholder="Старый пароль"
                     type="password"
                     onChange={(e) => setOldPassword(e.target.value)}
                     value={oldPassword}
              />
              {passwordFlag
              && <TextNotification>Пароли не совпадают</TextNotification>}
              <Input large
                     large
                     name="password"
                     placeholder="Новый пароль"
                     type="password"
                     onChange={(e) => setNewPassword(e.target.value)}
                     value={newPassword}
              />
              <Input large
                     large
                     name="password"
                     placeholder="Повторите пароль"
                     type="password"
                     onChange={(e) => setNewPasswordRepeat(e.target.value)}
                     value={newPasswordRepeat}
              />
              <ButtonLarge onClick={onSubmitPassword}>Сохранить</ButtonLarge>
            </FormPassword>
          </ChildContainer>
          <ChildContainer>
            <FormInformation>
              <TitleForm>Email</TitleForm>
              <Input large
                     large
                     name="email"
                     type="email"
                     onChange={(e) => setOtherEmail(e.target.value)}
                     value={otherEmail}
              />
              <TitleForm>Номер телефона</TitleForm>
              <Input large
                     large
                     name="phoneNumber"
                     onChange={(e) => setPhoneNumber(e.target.value)}
                     value={phoneNumber}
              />
              <TitleForm>О себе</TitleForm>
              <Input large
                     large
                     name="about"
                     onChange={(e) => setAbout(e.target.value)}
                     value={about}
              />
              <TitleForm>LinkedIn</TitleForm>
              <Input large
                     large
                     name="LinkedIn"
                     onChange={(e) => setLinkedIn(e.target.value)}
                     value={linkedIn}
              />
              <TitleForm>Instagram</TitleForm>
              <Input large
                     large
                     name="Instagram"
                     onChange={(e) => setInstagram(e.target.value)}
                     value={instagram}
              />
              <TitleForm>Facebook</TitleForm>
              <Input large
                     large
                     name="Facebook"
                     onChange={(e) => setFacebook(e.target.value)}
                     value={facebook}
              />
              <TitleForm>Telegram</TitleForm>
              <Input large
                     large
                     name="Telegram"
                     onChange={(e) => setTelegram(e.target.value)}
                     value={telegram}
              />
              <TitleForm>VK</TitleForm>
              <Input large
                     large
                     name="VK"
                     onChange={(e) => setVK(e.target.value)}
                     value={vk}
              />
              <ButtonLarge onClick={onSubmit}>Сохранить</ButtonLarge>
            </FormInformation>
          </ChildContainer>
        </MainContainer>
      )}
    </>
  );
};
export default SettingsPage;