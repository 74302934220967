import styled from '@emotion/styled';
import { primary } from './colors';
import { defaultFont } from './font';

/*
  props:
    1. white
    2. fwNormal
    3. medium
 */

const Button = styled.button`
  border-radius: 4px;
  border: none;
  background: ${primary};
  padding: 8px 114px;
  font-family: ${defaultFont}, sans-serif;
  font-weight: 800;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  color: white;
  cursor: pointer;
  &:active {
    background: #8679f7;
  } 

  ${(props) => props.white && `
      background: white;
      color: ${primary};
      border: 2px solid ${primary}; 
  `};

  ${(props) => props.fwNormal && `
      font-weight: normal;
  `}
  
  ${(props) => props.medium && `
      padding: 5.5px 89px;
  `}
  ${(props) => props.nav && `
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      font-size: 24px;
      line-height: 28px;
      padding: 5px 15px;
      background: none;
      text-decoration: none;
      color: #000000;
      &:active {
        background: #969696;
      } 
  } 
  `}
`;

export default Button;
